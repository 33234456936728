import * as React from "react";
import { Link } from "gatsby";

import Layout from "../../components/Layout";
import Seo from "../../components/Seo";

import Old2005BlogPage from "../../components/Old2005BlogPage";

const OldBlogPage = () => (
  <Layout>
    <Seo title="Old 2005 Blog—October 2005" />
	<Old2005BlogPage>
		<h2>October 2005</h2>






		<h3 className="blogdate">
		Monday, October 31, 2005
		</h3>






		<h4 className="blogitemtitle">
		Update on gigs in Rockhampton
		</h4>





			<div className="blogitembody">
			I have to correct some errors and add some more!<br /><ul><li> Wednesday nights @ O' Dowd's Irish Pub, 6:30 pm. <a href="http://www.odowds.com.au">http://www.odowds.com.au</a></li><li>Tuesday, November 1 @ The Glenmore Hotel, 12:00 pm - 4:00 pm (Melbourne Cup). <a href="http://www.cambradlodgemotel.com.au/">http://www.cambradlodgemotel.com.au/</a></li><li>Thursday, November 17 @ The Criterion Hotel (The Newsroom), 8:30 pm - 12:30 am.  <a href="http://www.thecriterion.com.au/">http://www.thecriterion.com.au/</a></li><li>Sunday, December 4 @ Allenstown Hotel, 2:00 pm - 6:00 pm</li></ul>
			</div>
			<div className="blogitemfooter">
			posted by Jesse @ 5:32 PM &nbsp;
			</div>






		<h3 className="blogdate">
		Friday, October 21, 2005
		</h3>






		<h4 className="blogitemtitle">
		More gigs in Rockhampton, Queensland
		</h4>





			<div className="blogitembody">
			Upcoming gigs:<br /><br /><ul><li>Wednesday nights @ O' Dowd's Irish Pub, 7:00 pm.  <a href="http://www.odowds.com.au">http://www.odowds.com.au</a></li><li>Wednesday, November 2 @ The Glenmore Hotel, 12:00 pm - 4:00 pm (Melbourne Cup).  <a href="http://www.cambradlodgemotel.com.au/">http://www.cambradlodgemotel.com.au/</a></li><li>Sunday, December 4 @ Allenstown Hotel, 2:00 pm - 6:00 pm</li></ul>
			</div>
			<div className="blogitemfooter">
			posted by Jesse @ 12:48 PM &nbsp;
			</div>






		<h3 className="blogdate">
		Friday, October 14, 2005
		</h3>






		<h4 className="blogitemtitle">
		Wednesday, October 19 @ O'Dowd's Irish Pub
		</h4>





			<div className="blogitembody">
			Finally, a gig in Rockhampton!  I start making noise at 8:00 pm, so if you are in the area, come on in!  If you are not in the area, you should change that and then come on in!
			</div>
			<div className="blogitemfooter">
			posted by Jesse @ 1:32 PM &nbsp;
			</div>








		<p>
			<Link to="/blog2005/">Go back to the blog index page</Link>
		</p>
</Old2005BlogPage>
  </Layout>
)

export default OldBlogPage;
